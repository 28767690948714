.main {
  background-color: #301d0e;
  background-repeat: no-repeat;
  background-size: 50vh 85%;
  background-image: url(https://leatherspa.imgix.net/shield.svg);
  background-position: right -12.5px top 25%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.card {
  border-radius: 0;
  min-width: 300px;
  max-width: 300px;
  margin-top: 3em;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
}

.disclaimer {
  color: white;
  padding: 20px 0 25px 0;
}

.cardHeader {
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo {
  max-width: 100px;
}

.heading {
  color: black;
  font-size: 20px;
  margin-bottom: 0;
}

.form {
  padding: 1em 0 0 0;
}

.button {
  margin-top: 1em;
}

.helpText {
  line-height: 1;
  margin: 0.5em 0;
}

@media only screen and (min-width: 768px) {
  .card {
    margin-top: 12em;
  }
}
